.App {
  text-align: center;
  font-family: sans-serif;
  background-color: #282c34;
  overflow-x: hidden;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.body {
  background-color: #282c34;
}

.image-cropper {
  width: 300px;
  height: 300px;
  position: relative;
  overflow: hidden;
  border-radius: 50%;
}

img {
  display: inline;
  margin: 0 auto;
  height: 100%;
  width: auto;
}

img.rounded {
  object-fit: cover;
  border-radius: 50%;
  height: 300px;
  width: 300px;
}

img.rounded-mobile {
  object-fit: cover;
  border-radius: 50%;
  height: 90%;
  width: 90%;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.sp-text {
  color: orange;
  font-style: normal;
  text-decoration: none;
}

.download-link {
  width: 280px;
  height: 60px;
  border-style: solid;
  border-color: orange;
  border-width: 1px;
  border-radius: 8px;
  color: white;
  @media (max-width: 800px) {
    scale: 0.8;
    margin-top: 30px;
  }
  margin-top: 80px;
  padding-top: 10px;
  margin-bottom: 50px;
  font-size: 20px;
  text-decoration: none;
  display: flex;
  justify-content: space-evenly;
  background-color: orange;
}

.download-link:hover {
  background-color: rgba(0, 0, 0, 0);
}
/* DEMO-SPECIFIC STYLES */
.typed-out {
  margin-left: 50px;
  overflow: hidden;
  border-right: 0.15em solid orange;
  white-space: nowrap;
  font-size: 3rem;
  width: 0;
  animation: typing 1s steps(20, end) forwards;
}

/* .container {
  display: inline-block;
} */

@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 75%;
  }
}

.divider {
  width: 100%;
  height: 2px;
  background-color: orange;
}

.experiences-container {
  margin-top: 40px;
  margin-left: 60px;
  width: 95%;
  height: 550px;
  align-items: center;
}

@media (min-width: 1800px) {
  /* Styles for width >= 1024px */
  .experiences-container {
    margin-left: 9%;
  }
}

.experience {
  border-style: solid;
  border-color: orange;
  border-width: 1px;
  border-radius: 10px;
  width: 400px;
  height: 500px;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.experience:hover {
  transform: scale(0.97);
}

.company-photo {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.company-image {
  width: 75px;
  height: 75px;
  object-fit: cover;
  object-position: 0 -2%;
  border-radius: 100%;
}

.slider-container {
  margin-top: 40px;
}

.react-multi-carousel-dot-list {
  position: relative;
  margin-top: 30px;
  @media (min-width: 800px) {
    margin-right: 60px;
  }
  color: orange;
}

.exp-title {
  color: white;
  font-style: normal;
  text-decoration: none;
  font-size: 25px;
  font-weight: bold;
}

.experiences-container-mobile {
  margin-top: 40px;
  scale: 0.9;
  width: 95%;
  height: 550px;
  align-items: center;
}

.experience-mobile {
  border-style: solid;
  border-color: orange;
  border-width: 1px;
  border-radius: 10px;
  width: 30 %;
  height: 500px;
  align-items: center;
}

.project-card-desktop {
  width: 900px;
  height: 200px;
  display: flex;
  flex-direction: row;
}

.project-card-mobile {
  width: 900px;
  height: 200px;
  display: flex;
  flex-direction: row;
  scale: 0.5;
  margin-left: 40px;
}

.project-image {
  width: 275px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.project-link {
  height: 98%;
  margin: 0px;
  border-left: orange 2px solid;
  border-top: orange 2px solid;
  border-bottom: orange 2px solid;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.project-link:hover .project-image {
  opacity: 0.5;
}

.external-link-icon {
  position: absolute;
  z-index: 10;
  color: white;
  width: 50px;
  height: 50px;
}

.project-text-content {
  width: 60%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  border: orange 2px solid;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.project-title {
  margin-top: 20px;
  font-size: 30px;
  font-weight: bold;
  color: white;
  text-decoration: none;
}

.project-title:hover {
  margin-top: 20px;
  font-size: 30px;
  font-weight: bold;
  color: orange;
}

.project-description {
  font-size: 20px;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.tech-list {
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: 20px;
  gap: 20px;
  color: orange;
}

.project-container-desktop {
  display: flex;
  flex-direction: column;
  gap: 50px;
  margin-top: 40px;
}

.project-cotainer-mobile {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}
